import { MouseEventHandler, useState } from "react";
import {
  Box,
  Checkbox,
  checkboxClasses,
  Divider,
  FormControlLabel,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import * as d3 from "d3";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import DashboardSkeleton from "~/pages/organization/dashboard/DashboardSkeleton";

type RawData = NonNullable<
  GetOrganizationOverviewQuery["organizationOverview"]
>["assetScores"];
type Datum = NonNullable<RawData>[0];
type Data = Datum[];

type GradeKey = keyof Omit<Datum["scores"], "__typename" | "total">;

export type AssetScoresOverTimeProps = {
  organizationMrn: string;
};

export function AssetScoresOverTime({
  organizationMrn,
}: AssetScoresOverTimeProps) {
  const theme = useTheme();

  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  const [tipOpen, setTipOpen] = useState(false);
  const [tipData, setTipData] = useState<Data[0] | null>(null);
  const [tipAnchorEl, setTipAnchorEl] = useState<null | SVGGElement>(null);
  const [activeGrades, setActiveGrades] = useState<GradeKey[]>([
    "A",
    "B",
    "C",
    "D",
    "F",
    "U",
    "Error",
  ]);

  if (result.loading) {
    return <DashboardSkeleton />;
  }

  const rawData = result.data?.organizationOverview?.assetScores || [];
  const data = parseData(rawData);

  const canvasWidth = 624;
  const canvasHeight = 232;
  const canvasPaddingTop = 8;
  const canvasPaddingBottom = 24;
  const canvasPaddingLeft = 64;
  const canvasPaddingRight = 24;

  const xDomain = data.map((d) => d.date);
  const xRange: [number, number] = [
    0 + canvasPaddingLeft,
    canvasWidth - canvasPaddingRight,
  ];
  const xScale = d3
    .scaleBand()
    .domain(xDomain)
    .range(xRange)
    .paddingInner(0.5)
    .paddingOuter(0.5);

  const fullYScale = false;

  const yMin = 0;
  const yMax =
    d3.max(data, (d) => {
      const { __typename, total, ...grades } = d.scores;
      const gradeEntries = Object.entries(grades) as [GradeKey, number][];
      if (fullYScale) {
        return d3.max(
          gradeEntries
            .filter(([grade]) => activeGrades.includes(grade))
            .map(([grade, value]) => value),
        );
      }
      return d3.max(Object.values(grades));
    }) || 0;
  const yDomain = [yMin, yMax];
  const yRange: [number, number] = [
    canvasHeight - canvasPaddingBottom,
    0 + canvasPaddingTop,
  ];
  const yScale = d3.scaleLinear().domain(yDomain).range(yRange);

  const statusDomain: GradeKey[] = ["A", "B", "C", "D", "F", "U", "Error"];
  const statusRange = [
    theme.palette.excellent.main,
    theme.palette.good.main,
    theme.palette.fair.main,
    theme.palette.poor.main,
    theme.palette.fail.main,
    theme.palette.unrated.main,
    theme.palette.errorScan.main,
  ];
  const statusLightRange = [
    theme.palette.excellent.light,
    theme.palette.good.light,
    theme.palette.fair.light,
    theme.palette.poor.light,
    theme.palette.fail.light,
    theme.palette.unrated.light,
    theme.palette.errorScan.light,
  ];
  const statusScale = d3
    .scaleOrdinal<string>()
    .domain(statusDomain)
    .range(statusRange);
  const statusLightScale = d3
    .scaleOrdinal<string>()
    .domain(statusDomain)
    .range(statusLightRange);

  const xAxisTickValues = [
    xScale.domain().at(0),
    xScale.domain().at(Math.floor(xScale.domain().length / 4)),
    xScale.domain().at(Math.floor(xScale.domain().length / 2)),
    xScale
      .domain()
      .at(Math.floor(xScale.domain().length - xScale.domain().length / 4)),
    xScale.domain().at(xScale.domain().length - 1),
  ].flatMap((d) => d ?? []);

  const yAxisTickValues = [
    yScale.domain().at(0),
    Math.round(d3.median(yScale.domain()) || 0),
    yScale.domain().at(yScale.domain().length - 1),
  ].flatMap((d) => d ?? []);

  const formatDateTick = d3.utcFormat("%b %_d");
  const formatDateTooltip = d3.utcFormat("%b %_d, %Y");

  const gradePath = (data: Datum[], grade: GradeKey) => {
    const lg = d3
      .line<Datum>()
      .x((x) => xScale(x.date) || 0)
      .y((y) => yScale(y.scores[grade]) || 0);
    return lg(data) || "";
  };

  let tipContent = <></>;
  if (tipData) {
    const { date } = tipData;
    const { __typename, total, ...scores } = tipData.scores;
    const gradeEntries = Object.entries(scores) as [GradeKey, number][];
    tipContent = (
      <Box>
        <Typography fontSize={10} fontWeight="bold" textAlign="center">
          {formatDateTooltip(parseApiDate(date)!)}
        </Typography>
        <Divider sx={{ my: 1, borderColor: "rgba(0,0,0,0.3)" }} />
        <Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ ml: 0, color: "text.primary", flexBasis: "50%" }}>
              <Typography fontSize={10} textTransform="uppercase">
                Score
              </Typography>
            </Box>
            <Box sx={{ ml: 1, color: "text.primary", flexBasis: "50%" }}>
              <Typography fontSize={10} textTransform="uppercase">
                Assets
              </Typography>
            </Box>
          </Box>
          {gradeEntries
            .filter(([grade]) => activeGrades.includes(grade))
            .map(([grade, value]) => (
              <Box key={grade} sx={{ display: "flex" }}>
                <Box
                  sx={{
                    ml: 0,
                    color: statusLightScale(grade),
                    flexBasis: "50%",
                  }}
                >
                  <Typography fontSize={10} fontWeight="bold">
                    {grade === "Error" ? "X" : grade}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ml: 1,
                    color: statusLightScale(grade),
                    flexBasis: "50%",
                  }}
                >
                  <Typography fontSize={10} fontWeight="bold">
                    {value}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    );
  }

  const handleCheckChange = (grade: GradeKey, checked: boolean) => {
    if (checked) {
      setActiveGrades([...activeGrades, grade]);
    } else {
      setActiveGrades(activeGrades.filter((a) => a !== grade));
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",

        ".chart-canvas": {
          display: "block",
          width: "624px",
          direction: "ltr",
        },

        ".chart-values": {
          ".chart-value circle": {
            transition: "stroke-opacity 0.2s",
            strokeOpacity: "0",
          },

          ".chart-value:hover circle": {
            strokeOpacity: "1",
          },
        },

        ".chart-box": {
          width: "95%",
          overflow: "auto",
          direction: "rtl",
        },
        ".chart-summary": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "end",
          textAlign: "right",
          ml: 1,
          fontSize: "100%",

          [theme.breakpoints.only("xs")]: {
            fontSize: "70%",
          },
          [theme.breakpoints.only("md")]: {
            fontSize: "70%",
          },

          ".summary-value": {
            fontSize: "2.5em",
            fontWeight: 800,
            lineHeight: "1.2em",
            whiteSpace: "nowrap",
          },
          ".summary-label": {
            fontSize: "1em",
            fontWeight: 800,
            lineHeight: "1.5em",
            whiteSpace: "nowrap",
          },
          ".summary-caption": {
            fontSize: "1em",
            color: "text.secondary",
            lineHeight: "1.5em",
            whiteSpace: "nowrap",
          },
        },
      }}
    >
      <Box className="chart-box">
        <svg
          className="chart-canvas"
          viewBox={`0 0 ${canvasWidth} ${canvasHeight}`}
        >
          <g className="chart-axis x">
            {xAxisTickValues.map((t, i) => (
              <g className="tick x" key={`${t}-${i}`}>
                <text
                  className="tick-label"
                  x={xScale(t)}
                  y={228}
                  width={50}
                  fontSize={12}
                  textAnchor="middle"
                  fill={theme.palette.text.primary}
                >
                  {formatDateTick(parseApiDate(t)!)}
                </text>
                <line
                  className="tick-line"
                  x1={xScale(t) || 0}
                  x2={xScale(t) || 0}
                  y1={yScale.range()[1]}
                  y2={yScale.range()[0]}
                  stroke={theme.palette.background.lighter}
                  strokeDasharray="2"
                  shapeRendering="crispEdges"
                />
              </g>
            ))}
          </g>
          <g className="chart-axis y">
            {yAxisTickValues.map((t, i) => (
              <g className="tick y" key={`${t}-${i}`}>
                <text
                  className="tick-label"
                  x={48}
                  y={yScale(t)}
                  fill="#B4B4B4"
                  textAnchor="end"
                  fontSize={12}
                  dominantBaseline="middle"
                >
                  {t}
                </text>
                <line
                  className="tick-line"
                  x1={56}
                  x2={xScale.range()[1] + 8}
                  y1={yScale(t)}
                  y2={yScale(t)}
                  stroke={theme.palette.background.lighter}
                  shapeRendering="crispEdges"
                />
              </g>
            ))}
          </g>
          <g>
            <g className="paths">
              {[...activeGrades].reverse().map((grade) => {
                return (
                  <path
                    key={grade}
                    d={gradePath(data, grade)}
                    stroke={statusScale(grade)}
                    fill="none"
                  />
                );
              })}
            </g>
            <Tooltip
              open={tipOpen}
              title={tipContent}
              arrow
              placement="top"
              disableInteractive
              onOpen={() => setTipOpen(true)}
              onClose={() => setTipOpen(false)}
              PopperProps={{
                sx: {
                  ".MuiTooltip-tooltip": {
                    p: 1,
                    fontSize: 14,
                    lineHeight: "24px",
                    fontWeight: (theme) => theme.typography.fontWeightRegular,
                  },
                },
                anchorEl: tipAnchorEl,
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}
            >
              <g className="chart-values">
                {data.map((d) => {
                  const { __typename, total, ...scores } = d.scores;
                  const gradeEntries = Object.entries(scores) as [
                    GradeKey,
                    number,
                  ][];

                  const handleBarMouseOver: MouseEventHandler<SVGGElement> = (
                    event,
                  ) => {
                    setTipAnchorEl(event.currentTarget);
                    setTipData(d);
                  };

                  return (
                    <g key={d.date} className="chart-value">
                      <g onMouseOver={handleBarMouseOver}>
                        {[...gradeEntries]
                          .filter(([grade]) => activeGrades.includes(grade))
                          .reverse()
                          .map(([grade, value]) => {
                            return (
                              <circle
                                cx={xScale(d.date)}
                                cy={yScale(value)}
                                r={3}
                                fill="none"
                                stroke={statusScale(grade)}
                                strokeWidth={2}
                                key={grade}
                              />
                            );
                          })}
                        <rect
                          x={(xScale(d.date) || 0) - xScale.bandwidth() / 2 - 4}
                          width={xScale.bandwidth() * 2}
                          y={0}
                          height={yScale.range()[0]}
                          fill="transparent"
                          key="pill-hover"
                        />
                      </g>
                    </g>
                  );
                })}
              </g>
            </Tooltip>
          </g>
        </svg>
      </Box>
      <Box className="chart-summary">
        {statusDomain.map((grade) => (
          <FormControlLabel
            key={grade}
            componentsProps={{ typography: { fontSize: 12 } }}
            control={
              <Checkbox
                size="small"
                checked={activeGrades.includes(grade)}
                onChange={(e, checked) => handleCheckChange(grade, checked)}
                sx={{
                  p: 0.5,
                  mr: 0.5,
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: statusScale(grade),
                  },
                }}
              />
            }
            label={grade === "Error" ? "X" : grade}
          />
        ))}
      </Box>
    </Box>
  );
}

const parseApiDate = d3.utcParse("%Y-%m-%d");
const formatApiDate = d3.utcFormat("%Y-%m-%d");

function parseData(rawData: RawData): Data {
  // From today+1
  const end = d3.utcDay.ceil(d3.utcDay.offset(d3.utcDay(), 1));
  // To 4 weeks prior
  const start = d3.utcDay.floor(d3.utcDay.offset(end, -29));
  const timeRange = d3.utcDay.range(start, end);

  return timeRange.map((d) => {
    const currDatum = rawData?.find((rd) =>
      isEqualDay(d, parseApiDate(rd.date)!),
    );
    const zeroDatum: Datum = {
      date: formatApiDate(d),
      scores: {
        A: 0,
        B: 0,
        C: 0,
        D: 0,
        F: 0,
        U: 0,
        Error: 0,
        total: 0,
        __typename: "RiskDistribution",
      },
      __typename: "AssetScoresDistribution",
    };
    return currDatum || zeroDatum;
  });
}

function isEqualDay(a: Date, b: Date) {
  return +d3.utcDay.floor(a) === +d3.utcDay.floor(b);
}
