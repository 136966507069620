import { Fragment } from "react/jsx-runtime";
import { SectionHeading } from "~/components/DetailsPage/components";
import { CveRiskFactors } from "~/components/vulnerabilities/cve-risk-factors";
import { useFindingOnAsset } from "../../hooks";
import { useParams } from "react-router-dom";
import { isAdvisoryOrVulnerabilityFinding } from "~/pages/findings/FindingOnAsset/utils";

export function VulnerabilityInsights() {
  const { finding, riskFactorsWithDocs } = useFindingOnAsset();
  const { vulnId } = useParams();

  return (
    <Fragment>
      <SectionHeading
        heading={vulnId ? "Vulnerability insights" : "Risk assessment"}
      />
      {/* <Typography className="summary-text" sx={{ mb: 3 }}>
     placeholder for vulnerability summary text
      </Typography> */}
      <CveRiskFactors
        id={`${vulnId ? "cve" : "advisory"}-risk-factors`}
        cvssScore={
          isAdvisoryOrVulnerabilityFinding(finding) ? finding?.cvss : null
        }
        epssScore={
          isAdvisoryOrVulnerabilityFinding(finding) ? finding?.epss : null
        }
        riskFactors={riskFactorsWithDocs}
        RiskFactorsProps={{ hideValues: true }}
        CVSSProps={{ flipContent: true }}
        EPSSProps={{ flipContent: true }}
      />
    </Fragment>
  );
}
