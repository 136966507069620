import { Box, TableCell, Typography } from "@mui/material";
import { OverflowHelper } from "~/components/OverflowHelper";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { PackageIcon } from "~/components/icons/mondoo/package";
import { CommonCellProps } from "~/pages/inventory/components/AssetSoftware/types";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";

export const TitleCell = ({ pckg, ...props }: CommonCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <OverflowHelper>
        <Typography
          fontSize="14px"
          fontWeight="bold"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          gap={1}
          data-testid="software-name"
        >
          <DynamicIcon iconId={pckg.iconId} defaultIcon={PackageIcon} />
          {pckg.packageName}
        </Typography>
      </OverflowHelper>
      <Box display="flex" gap={1} mt={1}>
        {pckg?.tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
};
