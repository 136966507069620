import { SxProps, TableCell, Typography, TypographyProps } from "@mui/material";
import { Clamp } from "../clamp/clamp";

type TopSecurityFindingCellProps = {
  type: string;
  title: string;
  size?: string;
  description?: string;
  TypographyProps?: TypographyProps;
};

export function TopSecurityFindingCell({
  type,
  size,
  ...props
}: TopSecurityFindingCellProps) {
  const isDashboardCard = size === "small";

  const checkTitle = props.title;
  const cveTitle = props?.description
    ? `${props.title}: ${props.description}`
    : props.title;

  const title = type === "CHECK" ? checkTitle : cveTitle;

  return (
    <TableCell>
      <Clamp lines={size === "small" ? 2 : 1}>
        <Typography
          sx={{
            fontSize: isDashboardCard ? 12 : 14,
            fontWeight: isDashboardCard ? 400 : 700,
            lineHeight: "16px",
          }}
          {...props.TypographyProps}
        >
          {title}
        </Typography>
      </Clamp>
    </TableCell>
  );
}
