import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { SectionHeading, Stats } from "../DetailsPage/components";
import { ShowMoreSummary } from "../ShowMore/ShowMoreSummary";
import { StatsProps } from "../DetailsPage/components/Stats/Stats";
import { TriageBar, TriageBarProps } from "../TriageBar/TriageBar";
import { InventoryPageHeader } from "~/routes/page-layout";

type DetailPageTopSectionProps = {
  content: {
    breadcrumbs: React.ReactNode | JSX.Element[];
    triageBar: TriageBarProps;
    variantTabs?: React.ReactNode;
    casesSummary?: React.ReactNode;
    summary?: string | null | undefined;
    stats: StatsProps["stats"];
    left?: {
      upper?: React.ReactNode;
      lower?: React.ReactNode;
    };
    right: React.ReactNode;
  };
};

export function DetailPageTopSection({ content }: DetailPageTopSectionProps) {
  return (
    <>
      <TriageBar
        {...{
          title: content.triageBar.title,
          titleIcon: content.triageBar.titleIcon,
          linkBack: content.triageBar.linkBack,
          riskFactors: content.triageBar.riskFactors,
          subheaders: content.triageBar.subheaders,
          toolbarItems: content.triageBar.toolbarItems,
          triage: content.triageBar.triage,
          availableRemediations: content.triageBar.availableRemediations,
        }}
      />
      <InventoryPageHeader disableYPadding={true}>
        <Grid2 size={{ xs: 12 }}>
          <Box id="detail-content-description">
            {content.variantTabs && content.variantTabs}
            <SectionHeading heading="Description" />
            {content.casesSummary && content.casesSummary}
            <Grid2
              container
              spacing={3}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Grid2 container size={{ xs: 12, sm: 12, md: 8 }}>
                <Grid2 size={12}>
                  <Typography textTransform="uppercase" fontWeight={700} mb={1}>
                    Summary
                  </Typography>
                  <Stats id="detail-content-stats" stats={content.stats} />
                  {content.summary && (
                    <ShowMoreSummary
                      id="detail-content-summary"
                      text={content.summary}
                    />
                  )}
                </Grid2>
                <Grid2
                  container
                  size={12}
                  spacing={3}
                  className="detail-content-left"
                >
                  {content.left?.upper && content.left.upper}
                  <Grid2
                    size={{ xs: 12, md: 6 }}
                    className="detail-content-left-lower"
                  >
                    {content.left?.lower && content.left.lower}
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2
                className="detail-content-right"
                size={{ xs: 12, sm: "auto", md: 4 }}
              >
                {content.right}
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </InventoryPageHeader>
    </>
  );
}
