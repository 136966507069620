import { useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMetrics, usePlatformVulnerabilityConfiguration } from "~/hooks";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { getColor } from "~/lib/colors";
import { pluralize } from "~/lib/pluralize";
import { Space } from "~/lib/types";
import {
  FindingType,
  Score,
  ScoreRating,
  ScoreStateFilter,
  useGetCvEsQuery,
} from "~/operations";
import { Metrics } from "~/utils/arrow";
import { Asset } from "../asset/types";

type UseAssetInsightsProps = {
  assetMrn: string;
  space: Space;
  scope: SpaceOrWorkspaceScope;
  insights: Asset["insights"];
  exceptionsCount: number;
  isCicd: boolean;
};

type AssetInsightsItem = {
  title: string;
  score: Pick<Score, "riskValue" | "riskRating"> | undefined;
  count: string;
  exceptionsCount?: string;
  color: string;
  disabled: boolean;
  to?: string;
};

export function useAssetInsights({
  assetMrn,
  insights,
  space,
  scope,
  exceptionsCount,
  isCicd,
}: UseAssetInsightsProps) {
  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });
  const theme = useTheme();
  const [currSearchParams] = useSearchParams();

  const { checksRatingCounts } = useMetrics({
    entityMrn: assetMrn,
    metricMrns: [Metrics.ChecksRatingCounts],
  });

  const { data: cveFindingsData } = useGetCvEsQuery({
    variables: {
      scopeMrn: assetMrn,
      first: 1,
      filter: {
        types: [FindingType.Cve],
        state: ScoreStateFilter.Open,
        includeCicd: isCicd,
      },
    },
  });

  const cveFindingsUnion = cveFindingsData?.findings;
  const cveFindingsResults =
    cveFindingsUnion?.__typename === "FindingsConnection"
      ? cveFindingsUnion
      : undefined;

  const { critical, high } = checksRatingCounts;
  const priorityFindingsCount = critical + high;

  const cvesCount = cveFindingsResults?.totalCount || 0;

  const insightHref = (key: string) => {
    const paths: Record<string, string | null> = {
      security: "checks",
      vulnerability: "vulnerabilities",
      exceptions: "exceptions",
    };
    const params: Record<string, URLSearchParams | null> = {
      security: new URLSearchParams({ impact: "critical,high" }),
    };
    const path = paths[key];
    const projectId = currSearchParams.get("projectId");
    const jobId = currSearchParams.get("jobId");
    const searchParams = new URLSearchParams([
      ...scope.params,
      ...(projectId && jobId ? new URLSearchParams({ projectId, jobId }) : []),
      ...(params[key] || []),
    ]);
    if (path) {
      return `${path}?${searchParams}`;
    }
  };

  const unknownScore = {
    riskRating: ScoreRating.None,
    riskValue: 0,
  };

  // extract typename from insights
  const securityInsight = insights?.security;
  const vulnsInsight = insights?.vulnerability || {
    __typename: "AssetInsightsVulnerability",
    score: {
      __typename: "Score",
      riskRating: ScoreRating.None,
      riskValue: 0,
    },
  };
  const insightsData = [vulnsInsight, securityInsight].flatMap((i) => i ?? []);
  const assetInsights: AssetInsightsItem[] = insightsData.map((value) => {
    const key =
      value.__typename === "AssetInsightsSecurity"
        ? "security"
        : value.__typename === "AssetInsightsVulnerability"
          ? "vulnerability"
          : "unknown";
    // check if the insight is disabled
    // if it's a vulnerability insight, check if the PVC is enabled
    // if it's a security insight, check if there are any policies
    const getDisabledStatus = () => {
      if (value.__typename === "AssetInsightsVulnerability" && !value) {
        return true;
      }
      if (value.__typename === "AssetInsightsVulnerability") {
        return !pvcIsEnabled;
      }
      if (value.__typename === "AssetInsightsSecurity") {
        return priorityFindingsCount === 0;
      }
      return false;
    };

    const disabledInsight = getDisabledStatus();

    const label =
      value.__typename === "AssetInsightsSecurity" ? "Priority finding" : "CVE";
    const count =
      value.__typename === "AssetInsightsSecurity"
        ? priorityFindingsCount
        : cvesCount;
    const countLabel = `${count} ${pluralize(label, count)}`;

    const exceptionsCountLabel =
      value.__typename === "AssetInsightsSecurity"
        ? `${exceptionsCount} ${pluralize("Exception", exceptionsCount)}`
        : undefined;

    const score = value.score || unknownScore;

    // get the color for the insight
    // if the insight is disabled, set the color to "disabled"
    const color = disabledInsight
      ? getColor(theme, "disabled")
      : getColor(theme, score.riskRating);

    return {
      title: pluralize(key, 2),
      score,
      count: countLabel,
      exceptionsCount: exceptionsCountLabel,
      color,
      disabled: disabledInsight,
      to: insightHref(key),
    };
  });

  return {
    insightsData: assetInsights,
  };
}
