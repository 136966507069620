import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { RemediationScripts } from "~/components/DetailsPage/components/Remediation/RemediationScripts";
import { useGetRemediationLazyQuery } from "~/operations";
import { EmptySection } from "~/components/vulnerabilities/empty-section";
import { SectionHeading } from "~/components/DetailsPage/components";
import { AnimatePresence } from "framer-motion";
import { Flex } from "../Flex";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { pluralize } from "~/lib/pluralize";
import { RemediationDropdownsSkeleton } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationDropdownsSkeleton";
import { RemediationRadioSkeleton } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationRadioSkeleton";
import { RemediationCheckboxSkeleton } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationCheckboxSkeleton";
import { groupBy } from "lodash";
import { useRemediationFilterV2 } from "~/components/DetailsPage/components/Remediation/hooks/useRemediationFilterV2";

export type Package = {
  name: string;
  arch?: string | null;
  version?: string | null;
};

export type FindingFixedByProps = {
  scopeMrn: string | undefined;
  findingId: string;
};

const PACKAGES_TO_REMEDIATE = {
  ALL: "all-packages",
  INDIVIDUAL: "individual-packages",
};

export function FindingRemediationV2({
  scopeMrn,
  findingId,
}: FindingFixedByProps) {
  const {
    setTitle,
    setVersion,
    setPackages,
    getTitle,
    getVersion,
    applyFilter,
    getPackages,
  } = useRemediationFilterV2();

  const [packagesType, setSelectedPackagesType] = useState(
    getPackages().length > 0
      ? PACKAGES_TO_REMEDIATE.INDIVIDUAL
      : PACKAGES_TO_REMEDIATE.ALL,
  );

  const [fetchRemediation, { data, loading: remediationLoading }] =
    useGetRemediationLazyQuery();

  const remediation =
    data?.remediation?.__typename === "VulnerabilityRemediationResponse"
      ? data?.remediation
      : undefined;

  const remediationPackages = remediation?.packages || [];
  const remediationScripts = remediation?.scripts || [];
  const ecosystems = remediation?.applicableEcosystems || [];
  const ecosystemsEntries = Object.entries(groupBy(ecosystems, (v) => v.title));

  useEffect(() => {
    if (!scopeMrn) return;

    fetchRemediation({
      variables: {
        input: {
          vulnerabilityId: findingId,
          scopeMrn,
          filter: {
            ...(getVersion() ? { ecosystem: getVersion() } : {}),
          },
          packages: getPackages().map((p) => ({
            name: p,
          })),
        },
      },
    }).then(({ data }) => {
      // Setting default dropdown and checkboxes values
      const remediation =
        data?.remediation?.__typename === "VulnerabilityRemediationResponse"
          ? data?.remediation
          : undefined;

      const highestVersionNamespace = remediation?.highestVersionEcosystem;

      const versionId =
        highestVersionNamespace || remediation?.applicableEcosystems?.[0]?.id;

      const versionTitle = (remediation?.applicableEcosystems || []).find(
        (ecosystem) => ecosystem.id === versionId,
      )?.title;

      const titleFromParams = getTitle();
      const versionFromParams = getVersion();

      if (!titleFromParams) {
        setTitle(versionTitle || "");
      }
      if (!versionFromParams) {
        setVersion(versionId || "");
      }
      applyFilter();
    });
  }, []);

  const fetchRemediations = () => {
    if (!scopeMrn) return;

    const selectedEcosystemId = ecosystems.find(
      (e) => e.id === getVersion(),
    )?.id;

    return fetchRemediation({
      variables: {
        input: {
          vulnerabilityId: findingId,
          scopeMrn,
          filter: {
            ...(selectedEcosystemId ? { ecosystem: selectedEcosystemId } : {}),
          },
          packages: remediationPackages
            .filter((rp) => getPackages().includes(rp.name))
            .map((p) => ({
              name: p.name,
              fixedVersion: p.version,
            })),
        },
      },
    });
  };

  const handleCheckboxChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (!scopeMrn) return;

    const selectedPackages = getPackages();

    const updatedSelection = selectedPackages.includes(id)
      ? selectedPackages.filter((checkboxId) => checkboxId !== id)
      : [...selectedPackages, id];

    setPackages(updatedSelection);
    applyFilter();
    fetchRemediations();
  };

  if (data?.remediation?.__typename === "VulnerabilityRemediationNotFound") {
    return (
      <EmptySection
        id="software-remediation-empty"
        text="There is currently no software to remediate"
      />
    );
  }

  if (
    data?.remediation?.__typename === "VulnerabilityRemediationNotSupported"
  ) {
    return (
      <EmptySection
        id="software-remediation-unsupported"
        text="Remediation for this package is not supported"
      />
    );
  }

  return (
    <Box component="form" id="remediation">
      <SectionHeading heading="Remediation" />
      <AnimatePresence>
        <Flex flexDirection="column">
          {remediationLoading ? (
            <RemediationDropdownsSkeleton />
          ) : (
            <>
              <Typography textTransform="uppercase" fontWeight={700} mb={2}>
                Select the version to fix
              </Typography>
              <Flex gap={3}>
                <Select
                  data-testid="ecosystem-title"
                  variant="outlined"
                  fullWidth
                  // disabled={!hasCreateCasePermissions}
                  sx={{
                    "&.MuiInputBase-root": {
                      bgcolor: "code.background",
                    },
                  }}
                  displayEmpty
                  value={getTitle()}
                  onChange={(event) => {
                    setTitle(event.target.value || "");
                    // when selecting ecosystem, preselect it's first available version in dropdown
                    const selectedEcosystemId = ecosystems.find(
                      (e) => e.title === event.target.value,
                    )?.id;
                    setVersion(selectedEcosystemId || "");
                    applyFilter();
                    fetchRemediations();
                  }}
                >
                  {ecosystemsEntries.map(([title, versions]) => {
                    const icon = versions?.[0]?.iconIDEnum;

                    return (
                      <MenuItem key={title} value={title}>
                        <Flex gap={1}>
                          {icon && <DynamicIcon iconId={icon} />}
                          {title}
                        </Flex>
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  data-testid="ecosystem-version"
                  variant="outlined"
                  data-test-id="case-select-input"
                  fullWidth
                  sx={{
                    "&.MuiInputBase-root": {
                      bgcolor: "code.background",
                    },
                  }}
                  displayEmpty
                  value={getVersion()}
                  onChange={(event) => {
                    setVersion(event.target.value || "");
                    applyFilter();
                    fetchRemediations();
                  }}
                >
                  {ecosystems
                    .filter((e) => e.title === getTitle())
                    .map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.version}
                      </MenuItem>
                    ))}
                </Select>
              </Flex>
            </>
          )}
        </Flex>
        <Typography textTransform="uppercase" fontWeight={700} mb={2} mt={5}>
          Define packages to include
        </Typography>
        {remediationLoading ? (
          <RemediationRadioSkeleton />
        ) : (
          <RadioGroup
            data-testid="ecosystem-packages-type"
            aria-labelledby="packages-to-include"
            sx={{
              "& .MuiFormControlLabel-label": { fontWeight: 700 },
            }}
            value={packagesType}
            onChange={(_e, value) => {
              setSelectedPackagesType(value);
              if (value === PACKAGES_TO_REMEDIATE.ALL) {
                setPackages([]);
              }

              applyFilter();
            }}
          >
            <FormControlLabel
              key="all"
              control={<Radio />}
              label={`Fix ${remediationPackages.length > 1 ? "all" : " "} ${remediationPackages.length} ${pluralize("package", remediationPackages.length)}`}
              value={PACKAGES_TO_REMEDIATE.ALL}
            />
            {remediationPackages.length > 1 && (
              <FormControlLabel
                key="individual"
                control={<Radio />}
                label="Fix individual packages"
                value={PACKAGES_TO_REMEDIATE.INDIVIDUAL}
              />
            )}
          </RadioGroup>
        )}
        {packagesType === PACKAGES_TO_REMEDIATE.INDIVIDUAL &&
          remediationPackages.length > 1 && (
            <>
              {remediationLoading ? (
                <RemediationCheckboxSkeleton />
              ) : (
                <Grid2 container data-testid="remediation-packages">
                  {remediationPackages.map((e) => (
                    <Grid2 key={e.id} size={{ xs: 12, md: 6, lg: 3 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={e.name}
                            checked={getPackages().includes(e.name)}
                            onChange={(event) =>
                              handleCheckboxChange(event, e.name)
                            }
                          />
                        }
                        label={e.name}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              )}
            </>
          )}
        <RemediationScripts
          scripts={remediationScripts}
          loading={remediationLoading}
        />
      </AnimatePresence>
    </Box>
  );
}
