import { Link as RouterLink } from "react-router-dom";
import { alpha, Box, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { HubCard } from "~/components/hub-card";
import { Asset } from "~/pages/inventory/asset/types";
import { useAssetInsights } from "~/pages/inventory/hooks";
import { Space } from "~/lib/types";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { DoNotDisturbOnOutlined } from "@mui/icons-material";
import { AverageRiskScoreBox } from "~/pages/security/policies/AverageRiskScoreBox";

type AssetInsightsProps = {
  assetMrn: string;
  space: Space;
  scope: SpaceOrWorkspaceScope;
  insights: Asset["insights"];
  exceptionsCount: number;
  isCicd: boolean;
};

export function AssetInsights({
  assetMrn,
  insights,
  space,
  scope,
  exceptionsCount,
  isCicd,
}: AssetInsightsProps) {
  const { insightsData } = useAssetInsights({
    assetMrn,
    insights,
    space,
    scope,
    exceptionsCount,
    isCicd,
  });

  return (
    <HubCard
      title="ASSET SCORE BREAKDOWN"
      loading={false}
      sx={{ minHeight: "100%" }}
    >
      <Grid container spacing={3} mt={0}>
        {isEmpty(insightsData) ? (
          <NoInsights />
        ) : (
          insightsData.map((insight) => {
            return (
              <Grid
                container
                key={insight.title}
                className="asset-insight"
                item
                columnGap={2}
                sm
                md={12 / insightsData.length}
                sx={{ flexWrap: "nowrap" }}
              >
                <Grid item xs="auto" className="asset-insight-score">
                  {insight.score && (
                    <AverageRiskScoreBox
                      riskValue={insight.score.riskValue}
                      riskRating={insight.score.riskRating}
                      inPreviewMode={insight.disabled}
                    />
                  )}
                  {!insight.score && (
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: theme.spacing(6),
                        height: theme.spacing(6),
                        p: 0.5,
                        borderRadius: 1,
                        background: alpha(insight.color, 0.1),
                      })}
                    >
                      <Typography
                        sx={(theme) => ({
                          color: insight.color,
                          fontSize: theme.spacing(4),
                          fontWeight: 700,
                        })}
                      >
                        –
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid container item xs justifyContent="space-between">
                  <Grid item>
                    <Box
                      {...(insight.to && {
                        component: RouterLink,
                        to: insight.to,
                      })}
                    >
                      <Typography
                        component="h6"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 700,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {insight.title}
                      </Typography>

                      <Box
                        sx={{ display: "flex", columnGap: 2, flexWrap: "wrap" }}
                      >
                        <Typography
                          color="text.secondary"
                          fontWeight={700}
                          className="asset-insight-label"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {insight.count}
                        </Typography>

                        {insight.exceptionsCount && (
                          <Typography
                            color="text.secondary"
                            className="asset-insight-exceptions-count"
                            sx={{
                              whiteSpace: "nowrap",
                              display: "inline-flex",
                              gap: 0.5,
                              alignItems: "center",
                            }}
                          >
                            <DoNotDisturbOnOutlined fontSize="inherit" />
                            {insight.exceptionsCount}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    </HubCard>
  );
}

const NoInsights = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        placeContent: "center",
        p: 2.5,
      }}
    >
      <Typography color="text.secondary" variant="subtitle1">
        No insights available
      </Typography>
    </Grid>
  );
};
