import { Grid2, Paper } from "@mui/material";
import { createPath, Link as RouterLink, parsePath } from "react-router-dom";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { AssetConfigurationSection } from "./asset-configuration-section";
import { Loading } from "./loading";
import { RiskFactors } from "~/pages/inventory/components/RiskFactors/RiskFactors";
import { AssetTopFindings } from "~/pages/inventory/components/AssetTopFindings/AssetTopFindings";

export function OverviewTab() {
  const {
    asset,
    assetPath,
    isCicd,
    hasMondooPlatformVulnPolicy,
    scope,
    space,
  } = useAssetOutlet();

  if (!assetPath) {
    return <Loading what="Overview Tab" />;
  }

  // until backend removes the platform vuln policy from the count, we are managing it here
  let policyCount = asset?.listPolicies?.totalCount || 0;
  // if there is a platform vulnerability policy, remove it from the count
  if (hasMondooPlatformVulnPolicy && policyCount > 0) {
    policyCount--;
  }

  const generateHref = (): string => {
    const parsedPath = parsePath(assetPath);
    const pathname = `${parsedPath.pathname}/policies`;
    const search = parsedPath.search;
    return createPath({ pathname, search });
  };

  return (
    <>
      <Grid2 container spacing={3} mt={3}>
        <Grid2 size={{ xs: 12, md: 4.25 }}>
          <RiskFactors />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 7.75 }}>
          <AssetTopFindings />
        </Grid2>
      </Grid2>
      <AssetConfigurationSection
        {...{ space, availablePermissions: space.iamActions }}
      />
    </>
  );
}
