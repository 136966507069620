import { Path, useFormContext } from "react-hook-form";
import {
  useGetAnnotationSuggestionsLazyQuery,
  useGetLabelSuggestionsLazyQuery,
  useGetWorkspaceConditionPossibleValuesLazyQuery,
} from "~/operations";
import { KeyboardEvent, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import {
  ConditionType,
  RiskFactorConditionKeyValueField,
  RiskFactorConditionOperator,
  RiskFactorsConfigurationInput,
} from "../../../RiskFactorsConfigurationForm";
import { ConditionValuesNameType } from "../../types";

export const isConditionComplete = (condition: ConditionType) => {
  const conditionKeySelected = !!condition.formKey;
  const isOperatorSelected = !!condition.operator;
  const isValueSelected =
    (condition.keyValueCondition?.values || []).length > 0 &&
    condition.keyValueCondition?.values?.[0]?.value;

  return conditionKeySelected && isOperatorSelected && isValueSelected;
};

export const getIsKeyValueKeySelected = (
  key: any,
): key is RiskFactorConditionKeyValueField =>
  Object.values(RiskFactorConditionKeyValueField).includes(key);

type ConditionKey = "" | RiskFactorConditionKeyValueField;

type UseConditionRowParams = {
  fieldId: string;
  selectionIndex: number;
  conditionIndex: number;
  isReadonly: boolean;
};

export function useConditionRow({
  fieldId,
  selectionIndex,
  conditionIndex,
  isReadonly,
}: UseConditionRowParams) {
  const { watch, setValue, setError, trigger, formState } =
    useFormContext<RiskFactorsConfigurationInput>();

  const isDirty = Boolean(formState.dirtyFields[fieldId]);
  const isValid = !Boolean(formState.errors[fieldId]);

  const [searchParams] = useSearchParams();
  const [isValuesDropdownOpen, setIsValuesDropdownOpen] =
    useState<boolean>(false);
  // In case of condition values autocomplete we will ALWAYS need space mrn, not workspace mrn.
  const spaceId = searchParams.get("spaceId");
  const spaceMrn = spaceId
    ? `//captain.api.mondoo.app/spaces/${spaceId}`
    : undefined;
  const conditions = watch(
    `${fieldId}.selections.${selectionIndex}.conditions`,
  );
  const searchableFields: Array<RiskFactorConditionKeyValueField> = [
    RiskFactorConditionKeyValueField.Labels,
    RiskFactorConditionKeyValueField.Annotations,
  ];

  const [
    fetchConditionValues,
    { data: conditionValuesData, loading: isConditionValuesLoading, refetch },
  ] = useGetWorkspaceConditionPossibleValuesLazyQuery();

  const [
    fetchLabels,
    {
      data: labelsData,
      loading: isLabelsSuggestionsLoading,
      refetch: refetchLabels,
    },
  ] = useGetLabelSuggestionsLazyQuery();

  const [
    fetchAnnotation,
    {
      data: annotationsData,
      loading: isAnnotationsSuggestionsLoading,
      refetch: refetchAnnotations,
    },
  ] = useGetAnnotationSuggestionsLazyQuery();

  const conditionValue = watch(
    `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}`,
  );
  const conditionKey = watch(
    `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
  );

  const conditionFormDictionaryKey = watch(
    `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values.0.key`,
  );

  const conditionKeyIsSearchable = (key: ConditionKey) => {
    return key && searchableFields.includes(key);
  };

  const fetchMoreValues = (key: ConditionKey, value: string) => {
    if (!conditionKeyIsSearchable(conditionKey)) return;

    switch (conditionKey) {
      case RiskFactorConditionKeyValueField.Labels:
        refetchLabels({
          scopeMrn: spaceMrn || "",
          key: conditionFormDictionaryKey,
          query: value,
        });
        break;
      case RiskFactorConditionKeyValueField.Annotations:
        refetchAnnotations({
          scopeMrn: spaceMrn || "",
          key: conditionFormDictionaryKey,
          query: value,
        });
        break;
      default:
        refetch({
          input: {
            scopeMrn: spaceMrn || "",
            ...(value ? { query: value } : {}),
          },
        });
    }
  };

  useEffect(() => {
    if (!conditionKeyIsSearchable(conditionKey)) return;

    switch (conditionKey) {
      case RiskFactorConditionKeyValueField.Labels:
        fetchLabels({
          variables: {
            scopeMrn: spaceMrn || "",
            key: conditionFormDictionaryKey,
          },
        });
        return;
      case RiskFactorConditionKeyValueField.Annotations:
        fetchAnnotation({
          variables: {
            scopeMrn: spaceMrn || "",
            key: conditionFormDictionaryKey,
          },
        });
        return;
      default:
        fetchConditionValues({
          variables: {
            input: {
              scopeMrn: spaceMrn || "",
            },
          },
        });
    }
  }, [conditionKey, conditionFormDictionaryKey, spaceMrn]);

  const handleKeyChange = (
    name: `${string}.selections.${number}.conditions.${number}.formKey`,
    value: "" | RiskFactorConditionKeyValueField,
  ) => {
    setValue(name, value, { shouldDirty: true, shouldTouch: true });
    trigger(name);
  };

  const conditionKeys = [
    {
      label: "METADATA",
      values: [
        {
          label: "Tags/Labels",
          value: RiskFactorConditionKeyValueField.Labels,
        },
        {
          label: "Annotations",
          value: RiskFactorConditionKeyValueField.Annotations,
        },
      ],
    },
  ];

  const isKeySelected = !!conditionKey;
  const isKeyValueKeySelected = getIsKeyValueKeySelected(conditionKey);

  const getConditionValues = () => {
    if (isKeyValueKeySelected)
      return (conditionValue?.keyValueCondition?.values || []).map((v) =>
        String(v.value),
      );

    return [];
  };

  const conditionValues = getConditionValues();

  function getConditionValuesFieldName(): ConditionValuesNameType {
    return `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`;
  }

  const conditionValuesFieldName: ConditionValuesNameType =
    getConditionValuesFieldName();

  const isFirstCondition = conditionIndex === 0;

  const conditionOperator = RiskFactorConditionOperator.Or;

  const getSelectedKeyLabel = () => {
    for (const group of conditionKeys) {
      const targetValue = group.values.find((v) => v.value === conditionKey);
      if (targetValue) {
        return targetValue.label;
      }
    }
    return "";
  };

  const selectedConditionKeyLabel = getSelectedKeyLabel();

  const handleConditionValueRemove = (value: string) => {
    if (isReadonly) return;

    setValue(conditionValuesFieldName, [], {
      shouldDirty: true,
      shouldTouch: true,
    });

    const invalidConditions = conditions.filter(
      (condition) => !isConditionComplete(condition),
    );

    // invalidConditions.forEach((_) => {
    //   setError(conditionValuesFieldName, {
    //     message: "This condition is incomplete. Please add a selection.",
    //   });
    // });
  };

  const handleConditionKeyRemove = () => {
    if (isReadonly) return;

    setValue(
      `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
      "",
      { shouldDirty: true, shouldTouch: true },
    );
    setValue(
      `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`,
      [],
      { shouldDirty: true, shouldTouch: true },
    );
    trigger(conditionValuesFieldName);
  };

  const handleKeyValueKeyClick = (value: string) => {
    setValue(
      conditionValuesFieldName,
      [...(value ? [{ key: String(value), value: "" }] : [])],
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  const handleKeyValueValueClick = (value: string) => {
    setValue(
      conditionValuesFieldName,
      [{ key: conditionFormDictionaryKey, value }],
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
    trigger(conditionValuesFieldName);
    setIsValuesDropdownOpen(false);
  };

  const handleValuesItemClick = (value: string) => {
    // const castedCurrentValues = conditionValues.map((cv) => String(cv));
    // const isValueSelected = castedCurrentValues.includes(value);
    // if (isValueSelected) {
    //   setValue(
    //     conditionValuesFieldName,
    //     castedCurrentValues.filter((cv) => cv !== value),
    //     { shouldDirty: true, shouldTouch: true },
    //   );
    // } else {
    //   setValue(
    //     conditionValuesFieldName,
    //     [...castedCurrentValues, value as string],
    //     { shouldDirty: true, shouldTouch: true },
    //   );
    // }
    // trigger(conditionValuesFieldName);
  };

  const handleDropdownSearchFieldChange: TextFieldProps["onChange"] = debounce(
    (e) => {
      fetchMoreValues(conditionKey, e.target.value);
    },
    400,
  );

  const handleDropdownSearchFieldKeyUp = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter") {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        if (!isKeyValueKeySelected) {
          handleValuesItemClick(e.target.value);
          e.target.value = "";
        } else {
          if (!conditionFormDictionaryKey) {
            handleKeyValueKeyClick(e.target.value);
            e.target.value = "";
          } else {
            handleKeyValueValueClick(e.target.value);
            e.target.value = "";
          }
          trigger(conditionValuesFieldName);
        }
      }
    }
  };

  const handleValuesDropdownOpen = () => {
    setIsValuesDropdownOpen(true);
  };

  const handleValuesDropdownClose = () => {
    if (!conditionValue?.keyValueCondition?.values?.[0]?.value) {
      setValue(
        `${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.keyValueCondition.values`,
        [],
        { shouldDirty: true, shouldTouch: true },
      );
    }
    setIsValuesDropdownOpen(false);
  };

  const getConditionValuesOptions = () => {
    switch (conditionKey) {
      case RiskFactorConditionKeyValueField.Labels:
        return (labelsData?.labelSuggestions || []).map((option) => ({
          value: option,
          label: option,
        }));
      case RiskFactorConditionKeyValueField.Annotations:
        return (annotationsData?.annotationSuggestions || []).map((option) => ({
          value: option,
          label: option,
        }));
      default:
        return [];
    }
  };

  return {
    showIncludeLabel: isFirstCondition,
    showConditionOperator: !isFirstCondition,
    showConditionKeyLabel: isKeySelected,
    showRemoveConditionButton: !isReadonly && isDirty,
    isKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions: getConditionValuesOptions(),
    selectedConditionKeyLabel,
    conditionOperator,
    conditionValuesFieldName,
    conditionFormDictionaryKey,
    handleConditionValueRemove,
    handleConditionKeyRemove,
    handleKeyChange,
    handleValuesItemClick,
    handleKeyValueKeyClick,
    handleKeyValueValueClick,
    handleDropdownSearchFieldKeyUp,
    handleDropdownSearchFieldChange,
    isLoading:
      isLabelsSuggestionsLoading ||
      isAnnotationsSuggestionsLoading ||
      isConditionValuesLoading,
    isValuesDropdownOpen,
    isDictionaryKeySelected: isKeyValueKeySelected,
    handleValuesDropdownOpen,
    handleValuesDropdownClose,
  };
}
