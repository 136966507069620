import { useMemo, useState } from "react";
import { Button, Stack } from "@mui/material";
import { TagButton, TagButtonProps } from "./TagButton";
import { removeMondooLink } from "~/utils/findingUtils";

type TagButtonRowProps = {
  items: TagButtonProps["item"][];
};

export function TagButtonRow({ items }: TagButtonRowProps) {
  const [showMore, setShowMore] = useState(false);

  const shouldShowButton = items.length > 4;

  const shownItems = useMemo(() => {
    return items.slice(0, showMore ? items.length : 4).map((item) => ({
      ...item,
      key: removeMondooLink(item.key),
    }));
  }, [items, showMore]);

  const handleShowClick = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <>
      <Stack direction="row" gap={2} flexWrap="wrap" sx={{ mb: 1 }}>
        {shownItems.map((label) => {
          return <TagButton item={label} key={label.key} />;
        })}
      </Stack>

      <Button
        className="show-more-toggle"
        variant="text"
        color="secondary"
        onClick={handleShowClick}
        sx={{
          display: shouldShowButton ? "block" : "none",
          px: 0,
          background: "transparent",
          textTransform: "none",
          fontWeight: 600,
          "&:hover": { background: "transparent" },
        }}
      >
        Show {showMore ? "less" : "more"}
      </Button>
    </>
  );
}
