import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { PaletteMode } from "@mui/material";
import { LoadingPage } from "~/components/loading";
import { Org, SpaceData } from "~/lib/types";
import { isUnauthRoute } from "~/unauthenticatedApp/unauthenticatedApp";
import { Config } from "~/configuration_provider";
import {
  LoadOrganizationDocument,
  LoadSpaceDocument,
  LoadViewerDocument,
  LoadViewerQuery,
  useDeleteOrganizationMutation,
  useDeleteSpaceMutation,
  useLoadViewerQuery,
  UserState,
  useSetViewerSettingMutation,
  useUpdateOrganizationNameMutation,
  useUpdateSpaceMutation,
} from "~/operations";
import { parseJwt } from "~/lib/parseJwt";
import { useAuth } from "~/login/provider";
import { datadogRum } from "@datadog/browser-rum";

export type Region = {
  name: string;
  url: string;
};

export type Viewer = NonNullable<LoadViewerQuery["viewer"]>;

export type RawViewerSettings = LoadViewerQuery["viewerSettings"];

export type ViewerSettings = {
  last_space_id?: string;
  colorblind?: string;
  colormode?: PaletteMode;
  assetDoNotAskToDelete?: "true" | "false";
};

export type ViewerContextTypes =
  | {
      viewer: Viewer | undefined;
      viewerSettings: ViewerSettings | undefined;
      regions: Region[] | undefined;
      findRegion: (name: string) => Region | undefined;
      selectedRegion: Region;
      setSelectedRegion: (region: Region) => void;
      refetch: ReturnType<typeof useLoadViewerQuery>["refetch"];
      firstSpace: () => SpaceData | undefined;
      findOrg: (orgId: string) => Org | null | undefined;
      currentOrg: () => Org | null | undefined;
      updateOrgName: ReturnType<typeof useUpdateOrganizationNameMutation>[0];
      updateSpace: ReturnType<typeof useUpdateSpaceMutation>[0];
      deleteOrg: ReturnType<typeof useDeleteOrganizationMutation>[0];
      deleteSpace: ReturnType<typeof useDeleteSpaceMutation>[0];
      updateViewerSettings: ReturnType<typeof useSetViewerSettingMutation>[0];
    }
  | undefined;

export const ViewerContext = createContext<ViewerContextTypes>(undefined);

export type ViewerProviderProps = { children: ReactNode };

// parse regions from ENV
function parseRegions(): Region[] {
  const regionString = Config.VITE_REGIONS;

  if (regionString) {
    let regions: Region[] = [];
    const rows = regionString.toString().split("|");
    rows.map((row) => {
      const parts = row.split(",");
      if (parts.length == 2) {
        regions.push({ name: parts[0], url: parts[1] });
      }
    });

    return regions;
  }

  return [{ name: "US", url: Config.VITE_API_URL + "" }];
}

export const regions: Region[] = parseRegions();

export function selectedApiEndpoint(): string {
  return selectedRegionFromLocalStorage().url;
}

function selectedRegionFromLocalStorage(): Region {
  const regionName = localStorage.getItem("regionName");
  const found = regions.find((region) => region.name === regionName);
  if (found) {
    return found;
  }
  return regions[0];
}

export function ViewerProvider({ children }: ViewerProviderProps) {
  const auth = useAuth();
  const navigate = useNavigate();

  // APOLLO
  const { loading, data, refetch } = useLoadViewerQuery({
    errorPolicy: "all",
  });

  const viewer = data?.viewer ? data.viewer : undefined;
  const viewerSettings = data?.viewerSettings
    ? buildViewerSettings(data.viewerSettings)
    : undefined;

  useEffect(() => {
    if (viewer && viewerSettings) {
      // load up the viewer and viewerSettings data into context
      loadViewer(viewer, viewerSettings);

      if (Config.VITE_DATADOG_ENABLE === "true") {
        datadogRum.setUser({
          id: viewer.mrn,
          name: viewer.name,
          email: viewer.email ?? undefined,
        });
      }
    }
    // we run these functions again only if Viewer data changes.
  }, [viewer, viewerSettings]);

  const loadViewer = async (viewer: Viewer, settings: ViewerSettings) => {
    // Navigate to last space used if loading into
    // top-level dashboard and lastSpaceId is set
    const lastSpaceId = settings.last_space_id;

    const isRootPath = window.location.pathname === "/";
    const isRedirectPath =
      isRootPath || isUnauthRoute(window.location.pathname);

    const isFirebaseAuth = Config.VITE_AUTH_PROVIDER === "firebase";
    const viewerIsEnabled = viewer.state === UserState.Enabled;
    const viewerIsSsoUser = await (async () => {
      if (!isFirebaseAuth || !auth.state.user) return false;
      const idToken = await auth.state.user.getIdToken();
      const token = parseJwt(idToken);
      const signinprovider = token?.firebase?.sign_in_provider as string;
      return signinprovider.startsWith("saml");
    })();

    const shouldRedirect =
      isRedirectPath && (viewerIsEnabled || viewerIsSsoUser);

    if (shouldRedirect) {
      if (lastSpaceId) {
        // Redirect to dashboard for last visited space
        navigate(`/space/overview?spaceId=${lastSpaceId}`);
      } else {
        navigate("/dashboard");
      }
    }
  };

  // Find a single organization using the the organization ID
  const findOrg = (orgId?: string | null): Org | null | undefined => {
    if (!viewer) return null;
    const orgs = viewer.organizations;
    if (!orgs) return null;
    return orgs.find((x: Org) => x.id === orgId);
  };

  const currentOrg = (): Org | null | undefined => {
    const queryParams = new URLSearchParams(window.location.search);

    // extract org information
    const orgId = queryParams.get("organizationId");
    return findOrg(orgId);
  };

  // LoadFirstSpace
  // useLoadFirstSpace
  const firstSpace = (): SpaceData | undefined => {
    return viewer?.firstSpace || undefined;
  };

  const [selectedRegion, _setSelectedRegion] = useState<Region>(
    selectedRegionFromLocalStorage(),
  );

  function findRegion(name: string): Region | undefined {
    for (let i = 0; i < regions.length; i++) {
      if (regions[i].name == name) {
        return regions[i];
      }
    }
    console.log("Warning, no region found for " + name);
  }

  function setSelectedRegion(region: Region | undefined) {
    if (region == undefined) {
      return;
    }
    if (selectedRegion.name == region.name) {
      return;
    }
    localStorage.setItem("regionName", region.name);
    _setSelectedRegion(region);
    window.location.reload();
  }

  //// APOLLO MUTATIONS
  const [updateViewerSettings] = useSetViewerSettingMutation();

  // Update an organization's display name
  const [updateOrgName] = useUpdateOrganizationNameMutation();

  // Delete an organization
  const [deleteOrg] = useDeleteOrganizationMutation({
    refetchQueries: [LoadViewerDocument, LoadOrganizationDocument],
  });

  // Update a space's display name
  const [updateSpace] = useUpdateSpaceMutation({
    refetchQueries: [LoadViewerDocument, LoadSpaceDocument],
  });

  // Delete a space from within an organization
  const [deleteSpace] = useDeleteSpaceMutation({
    refetchQueries: [LoadViewerDocument, LoadSpaceDocument],
  });

  if (loading || !viewer) {
    return <LoadingPage />;
  }

  return (
    <ViewerContext.Provider
      value={{
        viewer,
        regions,
        findRegion,
        selectedRegion,
        setSelectedRegion,
        viewerSettings,
        findOrg,
        currentOrg,
        refetch,
        firstSpace,
        updateOrgName,
        updateSpace,
        updateViewerSettings,
        deleteOrg,
        deleteSpace,
      }}
    >
      {viewer && children}
    </ViewerContext.Provider>
  );
}

export function useViewer() {
  const context = useContext(ViewerContext);
  if (context === undefined) {
    throw new Error("useViewer must be used within a ViewerProvider");
  }
  return context;
}

const buildViewerSettings = (
  rawSettings: RawViewerSettings,
): ViewerSettings => {
  const settings: ViewerSettings = {};
  rawSettings.forEach(({ key, value }) => {
    if (
      key === "assetDoNotAskToDelete" &&
      (value === "true" || value === "false")
    ) {
      settings.assetDoNotAskToDelete = value;
    }

    if (key === "colorblind") {
      settings.colorblind = value;
    }

    if (key === "colormode" && (value === "light" || value === "dark")) {
      settings.colormode = value;
    }

    if (key === "last_space_id") {
      settings.last_space_id = value;
    }
  });
  return settings;
};
