import {
  Avatar,
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ChecklistIcon, DefaultRiskAction } from "~/components/icons";
import { useAssetOutlet } from "../../asset";
import {
  AdvisoryFinding,
  CheckFinding,
  CveFinding,
  FindingsEdge,
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreStateFilter,
  useGetTopAssetFindingsQuery,
} from "~/operations";
import { useEffect } from "react";
import { TopSecurityFindingCell } from "~/components/FirewatchRow/TopSecurityFindingCell";
import { RankCell, RiskRatingCell } from "~/components/Cells";
import { Flex } from "~/components/Flex";

type AssetTopFindingsUnion = AdvisoryFinding | CveFinding | CheckFinding;

export function AssetTopFindings() {
  const { asset, space, isCicd } = useAssetOutlet();

  const { data, error, loading } = useGetTopAssetFindingsQuery({
    variables: {
      scopeMrn: asset.mrn,
      first: 5,
      orderBy: {
        field: FindingsOrderField.RiskValue,
        direction: OrderDirection.Desc,
      },
      filter: {
        includeCicd: isCicd,
        types: [FindingType.Check, FindingType.Cve, FindingType.Advisory],
        state: ScoreStateFilter.Open,
      },
    },
    skip: !asset.mrn,
  });

  const findings =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings.edges
      : undefined;

  const topFindings = findings?.map((edge) => {
    if (!edge.node || edge.node.__typename === "PackageFinding") return null; // Ensure node exists
    return edge.node;
  });
  // Filter out null values
  const filteredTopFindings = topFindings?.filter(
    (finding): finding is AssetTopFindingsUnion => finding !== null,
  );

  const hasFindings = filteredTopFindings && filteredTopFindings.length > 0;

  return (
    <Box component={Paper} sx={{ minHeight: 298 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ p: 3, pb: 2 }}
      >
        <Typography
          variant="h5"
          component="h3"
          fontWeight={700}
          sx={{ color: "text.primary", lineHeight: "24px" }}
        >
          TOP FINDINGS
        </Typography>
        <ChecklistIcon />
      </Stack>

      {!loading && !hasFindings ? (
        <Flex alignItems="center" justifyContent="center" sx={{ height: 150 }}>
          <Typography sx={{ fontWeight: 500, color: "text.secondary" }}>
            NO FINDINGS
          </Typography>
        </Flex>
      ) : (
        <TableContainer sx={{ px: 3, pb: 3 }}>
          <Table
            size="small"
            sx={{
              "& th": {
                borderBottom: "none",
                textTransform: "uppercase",
                fontSize: 10,
                lineHeight: "10px",
                pb: 2,
                color: "text.secondary",
              },
            }}
          >
            <TableHead
              sx={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              <TableCell width="50px" align="left" sx={{ pl: 0 }}>
                Rank
              </TableCell>
              <TableCell width="120px">Risk score</TableCell>
              <TableCell>Finding name</TableCell>
            </TableHead>
            <TableBody>
              {loading ? (
                // Temporary skeleton for loading state
                <SkeletonRows />
              ) : hasFindings ? (
                <FindingsRows findings={filteredTopFindings} />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

const FindingsRows = ({ findings }: { findings: AssetTopFindingsUnion[] }) => {
  return (
    <>
      {findings?.map((finding, i) => {
        const { rating, riskValue, title, id } = finding;

        return (
          <TableRow
            key={i}
            sx={{
              "& td": {
                borderBottom: "none",
              },
            }}
          >
            <RankCell id={i + 1} TableCellProps={{ sx: { pl: 0 } }} />
            <RiskRatingCell
              rating={{
                rating: rating,
                value: riskValue,
              }}
            />
            <TopSecurityFindingCell
              type={finding.__typename}
              title={title || id}
              TypographyProps={{
                sx: { fontSize: 12, fontWeight: 400 },
              }}
            />
          </TableRow>
        );
      })}
    </>
  );
};

const SkeletonRows = () => {
  return (
    <>
      {Array.from({ length: 5 }, (_, i) => (
        <TableRow
          key={i}
          sx={{
            "& td": {
              borderBottom: "none",
            },
          }}
        >
          <TableCell align="left" sx={{ pl: 0 }}>
            <Skeleton
              variant="circular"
              animation="wave"
              height={24}
              width={24}
            />
          </TableCell>
          <TableCell>
            <Skeleton
              variant="rounded"
              animation="wave"
              height={20}
              width={50}
            />
          </TableCell>
          <TableCell>
            <Skeleton
              variant="text"
              animation="wave"
              height={20}
              width="100%"
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
