import { Box, Grid2 } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { CveRiskFactors } from "~/components/vulnerabilities";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  CaseStatus,
  DocumentType,
  FindingType,
  GetCasesCountDocument,
  Mquery,
  MqueryDocsFieldsFragment,
  MqueryVariant,
  ScoreRating,
  ScoreStateFilter,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useGetCasesCountQuery,
  useGetTicketingIntegrationsQuery,
  useLoadMqueryQuery,
  useLoadPolicyQuery,
  Variant,
} from "~/operations";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { TabsVariant, VariantIcon } from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PolicyIdToMrn } from "~/lib/mrn";
import { CheckPolicyInfoTags } from "~/pages/space/security/components/Check/CheckPolicyInfoTags/CheckPolicyInfoTags";
import { ScoreBlock } from "~/pages/space/security/components/Check/ScoreBlock";
import { useCases } from "~/components/cases/hooks";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { CreateCasesIntegrationModal } from "~/components/cases/components/CreateCasesIntegrationModal";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { AffectedAssetsAdapter } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsAdapter";
import { CheckBreadcrumbs } from "~/pages/space/security/components/Check/CheckBreadcrumbs";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { DetailPageTopSection } from "~/components/DetailPageLayouts/DetailPageTopSection";
import { GenerateReportButton } from "~/pages/compliance/components/generate-report";
import { isFeatureEnabled } from "~/login/features";
import { getCheckStats } from "./utils";
import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { InventoryPageHeader } from "~/routes";
import { CheckQueries } from "~/pages/space/security/components/Check/CheckQueries";

type CheckProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type State = {
  docs?: MqueryDocsFieldsFragment | null;
  impact?: Mquery["impact"] | MqueryVariant["impact"] | null;
  mql?: Mquery["mql"] | MqueryVariant["mql"] | null;
  properties?: Mquery["properties"] | MqueryVariant["properties"] | null;
  filterID:
    | NonNullable<NonNullable<Mquery["variantQueries"]>[0]>["filterID"]
    | null;
};

export function Check({ space, scope, availablePermissions }: CheckProps) {
  const [searchParams] = useSearchParams();
  const policyId = searchParams.get("policyId") || "";
  const findingMrn = searchParams.get("findingMrn") || "";
  const { controlId, frameworkId } = useParams();
  const { state: routerState } = useLocation();

  const [selectedVariant, setSelectedVariant] = useState<State | null>(null);

  // In case we navigate here from policy checks list -> policyId is there, otherwise we don't wanna make request
  const { data: policyData } = useLoadPolicyQuery({
    variables: { input: { mrn: PolicyIdToMrn(policyId), spaceMrn: space.mrn } },
    skip: !policyId,
  });

  const {
    data: checkData,
    loading: checkLoading,
    error: checkError,
  } = useLoadMqueryQuery({
    variables: {
      input: {
        mrn: findingMrn,
      },
    },
  });

  const {
    data: aggData,
    loading: aggLoading,
    error: aggError,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scope.mrn || "",
      filter: {
        findingMrn: findingMrn,
        scoreType: AggregateScoreType.Check,
      },
    },
    skip: !scope.mrn,
  });

  const aggScores = aggData?.aggregateScores;
  const checkScore = (
    aggScores?.__typename === "AggregateScoresConnection"
      ? aggScores?.edges?.flatMap((edge) => edge?.node ?? [])
      : []
  )?.pop();

  const blastRadius = checkScore?.blastRadius;

  const [state, setState] = useState<State>({
    docs: null,
    impact: null,
    mql: null,
    properties: null,
    filterID: null,
  });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: findingMrn || "",
    scoreType: AggregateScoreType.Check,
  });

  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCase,
    hasListCasePermissions,
    hasIntegrationCreatePermission,
    hasCreateCasePermissions,
    loading: createCaseLoading,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions,
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: scope.mrn,
        findingMrns: [findingMrn],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !findingMrn || !hasListCasePermissions,
  });

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasCreateCasePermissions,
  });

  const {
    handleNodeClick,
    handleCancelClick,
    selectedEntities: selectedAssets,
    setSelectedEntities: setSelectedAssets,
  } = useExceptionsSelection();

  useEffect(() => {
    setState({
      docs: (selectedVariant || checkData?.mquery)?.docs,
      impact: (selectedVariant || checkData?.mquery)?.impact,
      mql: (selectedVariant || checkData?.mquery)?.mql,
      properties: (selectedVariant || checkData?.mquery)?.properties,
      filterID: selectedVariant?.filterID || null,
    });
  }, [selectedVariant, checkData]);

  useLayoutEffect(() => {
    if (checkData?.mquery?.variantQueries) {
      setSelectedVariant(checkData.mquery.variantQueries[0]);
    }
  }, [checkData]);

  const onVariantsChange = (variantFilterId: string) => {
    const selected =
      check?.variantQueries?.find(
        (variantQuery) => variantFilterId === variantQuery?.filterID,
      ) || null;
    setSelectedVariant(selected);
  };

  const createVariantButtons = (variants: Variant[]) => {
    return variants
      .map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      }))
      .sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
  };

  if (checkLoading || aggLoading || riskFactorsLoading)
    return <LoadingPage what="check details" />;

  if (checkError || aggError) return <LoadingFailedPage what="check details" />;

  const check = checkData?.mquery;

  const remediations = state.docs?.remediations;
  const hasRemediations =
    remediations?.__typename === "Remediations" &&
    remediations.entries.length > 0;
  const hasProperties = state.properties && state.properties.length > 0;
  const hasMql = Boolean(state.mql);
  const hasTicketingIntegration =
    (ticketingIntegrationsData?.ticketingIntegrations?.integrations?.length ||
      0) > 0;

  return (
    <>
      <DetailPageTopSection
        content={{
          breadcrumbs: (
            <CheckBreadcrumbs
              policyId={policyId}
              controlId={controlId}
              frameworkId={frameworkId}
              frameworkMrn={routerState?.frameworkMrn}
              policyData={policyData}
              control={routerState?.control}
              check={checkData}
              scopeParams={scope.params}
            />
          ),
          triageBar: {
            title: check?.title || "",
            linkBack: `/space/findings/checks?${scope.params}`,
            riskFactors,
            toolbarItems: [
              {
                id: "generate-report",
                component: (
                  <>
                    {isFeatureEnabled("Reporting") && (
                      <GenerateReportButton
                        documentType={DocumentType.CheckReport}
                        title={check?.title || "Generated Vulnerability Report"}
                        space={space}
                        checkId={String(check?.mrn)}
                      />
                    )}
                  </>
                ),
              },
            ],
            triage: true,
            availableRemediations: {
              exception: {
                active: false,
              },
              snooze: { active: false },
              ticket: {
                active: true,
                onClick: hasTicketingIntegration
                  ? () => {
                      setSelectedAssets([
                        {
                          mrn: findingMrn,
                          scopeMrn: scope.mrn,
                          groupId: "ALL_ASSETS",
                        },
                      ]);
                      handleCreateCaseModalOpen();
                    }
                  : handleCreateCaseIntegrationModalOpen,
              },
            },
          },
          ...(check?.variants &&
            check.variants.length > 0 && {
              variantTabs: (
                <Box mt={2}>
                  <TabsVariant
                    options={createVariantButtons(check?.variants)}
                    selectedTab={selectedVariant?.filterID || ""}
                    onTabChange={onVariantsChange}
                    mb={3}
                    data-testid="check-variants"
                  />
                </Box>
              ),
            }),
          ...(hasListCasePermissions &&
            (casesData?.cases.totalCount || 0 > 0) && {
              casesSummary: (
                <CasesSummary
                  count={casesData?.cases.totalCount || 0}
                  scope={scope}
                />
              ),
            }),
          summary: check?.docs?.desc,
          stats: getCheckStats({ blastRadius }),
          left: {
            ...(policyId && {
              upper: (
                <CheckPolicyInfoTags
                  version={policyData?.policy?.version}
                  groups={policyData?.policy?.groups
                    ?.map((group) => group.title)
                    ?.join(",")}
                />
              ),
            }),
          },
          right: (
            <ScoreBlock
              hasScore={Boolean(checkScore)}
              mainScore={checkScore?.riskValue}
              blastRadius={checkScore?.blastRadius}
              riskFactors={checkScore?.riskFactors}
              rating={checkScore?.rating || ScoreRating.None}
              hasError={aggScores?.__typename !== "AggregateScoresConnection"}
            />
          ),
        }}
      />
      <InventoryPageHeader disableYPadding>
        {riskFactorsWithDocs.length > 0 && (
          <Grid2 id="risk-factors" size={12}>
            <SectionHeading heading="Risk assessment" />
            <Box className="section-content">
              <CveRiskFactors
                id="cve-risk-factors"
                riskFactors={riskFactorsWithDocs}
              />
            </Box>
          </Grid2>
        )}

        {hasRemediations && (
          <Grid2 size={12}>
            <SectionHeading heading="Remediation" />
            <Box>
              {remediations.entries.map((remediation) => (
                <Markdown
                  key={remediation.id}
                  source={remediation.desc}
                  externalLinksInNew
                />
              ))}
            </Box>
          </Grid2>
        )}

        {(hasProperties || hasMql) && (
          <CheckQueries properties={state.properties} mql={state.mql} />
        )}

        {/* <AdvisoryContentDetails advisoryId={advisory.id} /> */}

        {/* <AdvisoryRemediation advisoryId={advisory.id} spaceMrn={space.mrn} /> */}

        {check?.mrn && (
          <AffectedAssetsAdapter
            filter={{
              mrn: check.mrn,
              types: [FindingType.Check],
              state: ScoreStateFilter.Open,
            }}
            scope={scope}
            emptyStateMessage="There are currently no exposed assets for this check."
            urlContextType={AssetContextualLinkType.Check}
            contextId={check?.mrn}
            selectionProps={{
              selectedEntities: selectedAssets,
              hasSelectPermissions: hasCreateCasePermissions,
              onCheckboxClick: handleNodeClick,
              getIsRowSelected: (rowAssetMrn) => {
                return Boolean(
                  selectedAssets.find(
                    (asset) => asset.scopeMrn === rowAssetMrn,
                  ),
                );
              },
              onCheckAll: (checked, edges) => {
                if (checked) {
                  setSelectedAssets(
                    (edges || []).map((edge) => ({
                      groupId: "",
                      scopeMrn: edge?.mrn || "",
                      mrn: findingMrn,
                      exception: null,
                    })),
                  );
                } else {
                  setSelectedAssets([]);
                }
              },
              onCheckboxChange: async (
                _: ChangeEvent<HTMLInputElement>,
                checked: boolean,
                asset,
              ) => {
                const nextSelected = selectedAssets.filter(
                  (s) => s.scopeMrn !== asset?.mrn,
                );
                if (checked) {
                  nextSelected.push({
                    groupId: "",
                    mrn: findingMrn,
                    scopeMrn: asset?.mrn || "",
                    exception: null,
                  });
                }

                setSelectedAssets(nextSelected);
              },
              renderToolbar: (totalCount) => {
                return (
                  selectedAssets.filter((a) => a.groupId !== "ALL_ASSETS")
                    .length > 0 && (
                    <ExceptionsToolbar
                      target="asset"
                      onCreateCaseClick={
                        hasTicketingIntegration
                          ? hasCreateCasePermissions
                            ? handleCreateCaseModalOpen
                            : undefined
                          : handleCreateCaseIntegrationModalOpen
                      }
                      onCancel={handleCancelClick}
                      selectedEntities={selectedAssets}
                      totalCount={totalCount}
                    />
                  )
                );
              },
            }}
          />
        )}
      </InventoryPageHeader>
      <CreateCasesIntegrationModal
        open={isCreateCaseIntegrationModalOpen}
        onClose={handleCreateCaseIntegrationModalClose}
        space={space}
        redirectTo={`${location.pathname}?${searchParams}`}
        hasIntegrationCreatePermission={hasIntegrationCreatePermission}
      />
      <CreateCaseModal
        open={isCreateCaseModalOpen}
        target="asset"
        loading={checkLoading || createCaseLoading}
        onClose={() => {
          handleCreateCaseModalClose();
          setSelectedAssets([]);
        }}
        onSave={handleCreateCase}
        selectedAssets={selectedAssets}
        space={space}
        scope={scope}
        hasCreateCasePermissions={hasCreateCasePermissions}
      />
    </>
  );
}
