import { ReactElement } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { CheckResult } from "../types";
import { ImpactRating } from "./impact-updated";

type CheckImpactTooltipProps = {
  children: ReactElement;
  result?: CheckResult;
  color: string;
  impact: ImpactRating;
  isRiskScore: boolean;
  isCVSS: boolean;
};

export const ImpactTooltipUpdated = ({
  children,
  result,
  color,
  impact,
  isRiskScore,
  isCVSS,
}: CheckImpactTooltipProps) => {
  return (
    <Tooltip
      title={
        <Box>
          {result && (
            <Typography fontSize={10} fontWeight="700">
              RESULT:{" "}
              <Typography
                variant="caption"
                fontSize={10}
                color={color}
                fontWeight="700"
              >
                {result.toUpperCase()}
              </Typography>
            </Typography>
          )}
          <Typography fontSize={10} fontWeight="700">
            {isRiskScore ? "RATING" : isCVSS ? "CVSS SCORE" : "IMPACT"}{" "}
            <Typography
              variant="caption"
              fontSize={10}
              color={color}
              fontWeight="700"
            >
              {`${impact.rating} ${impact.value && `(${impact.value})`}`}
            </Typography>
          </Typography>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "none",
            p: 1,
          },
        },
      }}
      placement="top"
      arrow
    >
      {children}
    </Tooltip>
  );
};
